import { useTranslation } from 'react-i18next';

import {
  Badge,
  Description,
  Observation,
  PlanCardContainer,
  PriceContainer,
  PriceText,
  Title,
  PlanButton
} from './plan-card-styles';

type PlanCardProps = {
  badgeText?: string;
  color: string;
  title: string;
  currency: string;
  price?: number;
  description: string;
  buttonText: string;
  isLoading?: boolean;
  buttonAction?;
  observation?: string;
  isBorderRadiusButton?: boolean;
  isCurrentPlan?: boolean;
  isDisabled?: boolean;
};

const PlanCard = ({
  badgeText,
  color,
  title,
  currency,
  price,
  description,
  buttonText,
  buttonAction,
  observation,
  isCurrentPlan,
  isBorderRadiusButton,
  isDisabled
}: PlanCardProps) => {
  const { t } = useTranslation('register-plan');

  const monthlyPrice = price / 12;

  return (
    <PlanCardContainer borderColor={color}>
      {badgeText && <Badge backgroundcolor={color}>{badgeText}</Badge>}
      <Title color={color}>{title}</Title>
      <PriceContainer>
        <PriceText>
          {currency}
          {monthlyPrice || monthlyPrice === 0
            ? ` ${monthlyPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
            : ''}
        </PriceText>
        {monthlyPrice ? '/mês*' : ''}
      </PriceContainer>
      <Description>{description} </Description>
      <PlanButton
        id={`${title ? title.toLowerCase() : title}`}
        onClick={!isCurrentPlan && buttonAction}
        style={
          isBorderRadiusButton ? { borderRadius: 50 } : { borderRadius: 8 }
        }
        backgroundColor={isCurrentPlan && '#63AB36'}
        variant={isCurrentPlan ? 'outlined' : 'contained'}
        disabled={isDisabled}
      >
        {isCurrentPlan ? t('current_plan') : buttonText}
      </PlanButton>
      <Observation>{observation}</Observation>
    </PlanCardContainer>
  );
};
export default PlanCard;
